.Bumper {

    display: flex;
    flex-flow: row wrap;

    position: absolute;
    z-index: 9999999;

    left: 0;
    bottom: 120px;

    width: 400px;

    padding: 10px 20px;
    // height: 150px;

    

    color: white;
    
    background: $deepPurple;

    transform: translate3d(-100%, 0, 0);

    transition:
        transform 600ms cubic-bezier(0.075, 0.82, 0.165, 1);


    
    
    &.show {
        
        transform: translate3d(0, 0, 0);
    }


    &__Label {

        width: 100%;

        font-size: 20px;
        line-height: 1em;
        
    }
    &__Name {

        width: 400px;
        
    
        font-size: 40px;
        line-height: 1em;
        
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    
        // background: blue;
    }
}


