.GameNew {

    position: fixed;
    z-index: 9999999;

    top: 0;
    left: 0;

    // width: 100vw;
    width: calc(100vw - 290px);

    // background: yellowgreen;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 76%);

    h2 {

        display: none;
    }


    &__Info {

        display: flex;
        flex-flow: row wrap;

        align-items: center;

        justify-content: center;

        // text-align: center;

        // background: red;
    }


    &__Team {

        display: flex;
        
        padding: 10px 20px;
    }


    &__Score {

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;


        // width: 260px;

        border-radius: 50px;

        padding: 10px 0;

        color: #fff;

        background: $deepPurple;

        

        .score {

            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            // padding: 0 20px;

            height: 32px;

            font-size: 32px;
            font-weight: 900;
            
            // background: black;

        }

        .gameType {

        
            // line-height: 32px;
            font-size: 12px;

            margin: 0 16px;
            padding: 6px 12px;

            border-radius: 50px;
            
            color: #000;
        
            background: $skyBlue;
        }

        .label {

            max-width: 100px;

            margin: 0 20px;


            line-height: 1em;

            font-size: 14px;
            font-weight: 500;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            
            text-align: center;
        }
    }
}

