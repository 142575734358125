@include for_mobile_wide {


    .Chat {

        order: 0;

        border: rgba($color: #fff, $alpha: 0.05) solid 1px;
        border-width: 0 0 1px;

        h2 {

            display: none;
        }

        &__Messages {

            display: none;
        }

        &__Input {

            display: none;
        }

        &__Reactions {

            font-size: 30px;
            margin: 0;
        }
    }
}