// media queries //
@mixin for_desktop {
	@media screen
	and (max-width : $desktop) {
		@content;
	}
}

@mixin for_tablet {
	@media screen
	and (max-width : $tablet) {
		@content;
	}
}

@mixin for_mobile_wide {
	@media screen
	and (max-width : $mobileWide) {
		@content;
	}
}


@mixin noselect() {

	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome and Opera */
}