@import "_reset";
@import "_normalize";
@import "_global";
@import "_typography";
// @import '_animation';

/* colors */
$skyBlue: #2feffd;
$deepPurple: #3f0444;
// $black: #0F1419;

body {
  margin: 0 auto;
  // overflow: hidden;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;

  background: #181819;
}

@import "container";

@import "Preloader";

@import "Streamer";

@import "Bumper";
@import "Bumper_mobile";

@import "Video";
@import "Video_tablet";

@import "Sidebar";

@import "Avatar";

@import "Players";

// @import 'Game';
@import "GameNew";
@import "GameNew_tablet";

@import "Chat";
@import "Chat_mobile";

// @media (prefers-reduced-motion: no-preference) {
// 	.App-logo {
// 		animation: App-logo-spin infinite 20s linear;
// 	}
// }

// @keyframes App-logo-spin {
// 	from {
// 		transform: rotate(0deg);
// 	}

// 	to {
// 		transform: rotate(360deg);
// 	}
// }

// .App {
// 	text-align: center;

// 	&-header {

// 		background-color: #282c34;
// 		min-height: 100vh;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		justify-content: center;
// 		font-size: calc(10px + 2vmin);
// 		color: white;
// 	}

// 	&-link {

// 		color: #61dafb;
// 	}
// }
