.Players {

    padding: 20px;
    // background: blue;

    border: rgba($color: #fff, $alpha: 0.05) solid 1px;

    border-width: 0 0 1px;

    color: #fff;

    &__Avatars {

        display: flex;
        flex-flow: row wrap;

    }

    @include for_mobile_wide {
        
        order: 1;
    }
}