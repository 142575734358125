.Avatar {

    display: flex;
    flex-flow: column;
    align-items: center;

    width: 60px; // default

    // background: red;


    &__Name {

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        text-align: center;
    }


    &__Image {

        border: 1px solid rgba(200, 200, 200, 0.8);
        border-radius: 50%;

        box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
    }



    &.big {

        width: 78px;
        // width: calc(25% - 5px);

        margin: 0 5px 20px 0;
    
        .Avatar {

            &__Image {
    
                width: 44px;
                height: 44px;

                margin: 0 0 5px;
            }
        
            &__Name {

                width: 100%;

                line-height: 1.2em;

                font-size: 13px;

            }
        }
    }


    &.game {
    
        width: 64px;
    
        margin: 0 5px 20px 0;
    
        .Avatar {
    
            &__Image {
    
                width: 60px;
                height: 60px;
    
                margin: 0 0 10px;
            }
    
            &__Name {
    
                width: 100%;
    
                line-height: 1.2em;
    
                font-size: 13px;
    
            }
        }
    }


    &.gameNew {
    
        width: 64px;
    
        margin: 0 5px;

        // @include for_mobile_wide {

        //     width: 44px;
        // }
    
        .Avatar {
    
            &__Image {
    
                width: 60px;
                height: 60px;

                margin: 0 0 10px;

                // @include for_mobile_wide {
                
                //     width: 40px;
                //     height: 40px;
                // }
    
            }
    
            &__Name {
    
                width: 100%;
    
                line-height: 1.2em;

                color: #fff;
    
                font-size: 13px;
    
            }
        }
    }



    &.small {

        width: 64px;
    
        .Avatar {
    
            &__Image {
    
                width: 24px;
                height: 24px;
            }

            &__Name {
                
                width: 100%;

                font-size: 10px;

                // background: red;
            }
        }
    }
}