.Preloader {

	display: flex;
	justify-content: center;
	align-items: center;

	width: 100vw;
	height: 100vh;
	
	color: #fff;

	// background: teal;
	// display: none;


	&.gone {
		display: none;
		visibility: hidden;
	}

	&__Label {

		position: relative;

		padding: 14px 0;
		overflow: hidden;

		text-transform: uppercase;

		@include noselect();

		&::after {

			content: '';
			
			position: absolute;
			bottom: 0; left: 0; right: 0;

			height: 1px;

			background: rgba($color: #ffffff, $alpha: 0.5);
		}

		&::before {
		
			content: '';
		
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		
			height: 1px;
		
			background: rgba($color: #ffffff, $alpha: 1);

			transform: translate3d(-100%, 0, 0);

			animation-name: loading;
			animation-duration: 1s;
			animation-iteration-count: infinite;
		}
	}
}


@keyframes loading {
	from {
		transform: translate3d(-100%, 0, 0);
	}

	to {
		transform: translate3d(100%, 0, 0);
	}
}