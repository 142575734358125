@include for_mobile_wide {

	.Video {

		position: relative;
		// z-index: 999999;

		// top: 0;
		// left: 0;

		width: 100vw;
		height: auto;

		&__Player {

			width: 100%;
			height: auto;

			margin: 0;

			&__Stream {

				width: 100%;
				height: auto;
			}

		}

		.Spacer {

			display: none;
		}
	}
}