@include for_mobile_wide {

    .GameNew {

        position: relative;

        width: 100vw;

        background: rgba($color: #181819, $alpha: 1);
        backdrop-filter: blur(16px);
        
        backdrop-filter: blur(16px) contrast(60%);
        -webkit-backdrop-filter: blur(16px) contrast(60%);

        border: rgba($color: #fff, $alpha: 0.05) solid 1px;
        
        border-width: 0 0 1px;



        h2 {
            
            display: block;

            color: #fff;

            // background: red;

            margin: 0;

            padding: 20px;
            
        }
        
        &__Info {

            justify-content: space-between;

            width: auto;

            padding: 0 20px;

            // background: red;
        }

        &__Team {

            padding: 0 0 20px;
            // margin: 10px 20px;

            width: auto;

            // background: yellowgreen;
        }

        &__Score {

            order: 3;

            width: 100%;

            margin: 0 0 20px;


            // .score {

            //     font-size: 20px
            // }

            // .gameType {

            //     margin: 0 8px;
            //     padding: 6px 8px;
            // }

            .label {

                max-width: 64px;
            }
        }

    }

}