/* Web Font */

// $fonts_path: '../../asset/webfont/';

// // $serif: "p-r", Arial, sans-serif; // Portrait Regular
// $sans: "sans",
// Arial,
// sans-serif; // Portrait Regular



// @font-face {

//     font-family: "sans";
//     font-weight: 400;
//     font-style: normal;

//     src: url("#{$fonts_path}sans-regular-web.woff") format('woff');

//     font-display: swap;
// }


// @font-face {

//     font-family: "sans";
//     font-weight: 500;
//     font-style: normal;

//     src: url("#{$fonts_path}sans-medium-web.woff") format('woff');

//     font-display: swap;
// }


// @font-face {

//     font-family: "sans";
//     font-weight: 700;
//     font-style: normal;

//     src: url("#{$fonts_path}sans-bold-web.woff") format('woff');

//     font-display: swap;
// }


// @font-face {

//     font-family: "sans";
//     font-weight: 900;
//     font-style: normal;

//     src: url("#{$fonts_path}sans-heavy-web.woff") format('woff');

//     font-display: swap;
// }


// @mixin regular {
//     font-family: $sans;
//     font-weight: 400;
// }

// @mixin medium {
//     font-family: $sans;
//     font-weight: 500;
// }

// @mixin bold {
//     font-family: $sans;
//     font-weight: 700;
// }

// @mixin heavy {
//     font-family: $sans;
//     font-weight: 900;
// }

body {

    font-family: Helvetica, Arial, sans-serif;
}


h1 {

    // @include heavy();

    font-size: 17px;

    // line-height: calc(20/17px);
    line-height: 1.17em;

    margin: 0 0 8px;
}

h2 {

    // @include bold();

    font-weight: 900;

    font-size: 20px;

    line-height: 1.33em;

    margin: 0 0 24px;
}

h3 {

    // @include regular();

    font-size: 14px;

    line-height: 1.14em;

    margin: 0 0 20px;

    // strong {

    //     @include medium();
    // }
}