.Streamer {

    position: absolute;
    z-index: 999999999999999;

    display: flex;
    flex-flow: column wrap;
    justify-content:center;
    align-items: center;

    
    width: 100vw;
    height: 100vh;

    font-size: 14px;
    line-height: 1.2em;

    color: #fff;

    
    background: #000;

    &.hide {

        display: none;
        opacity: 0;
        visibility: hidden;
    }


    &__Avatar {

        width: 100px;
        height: 100px;

        margin: 0 0 20px;

        border-radius: 9999px;

        background: #eee;
    }

    &__Name {

        margin: 0 0 1em;

        @include noselect();
    }
    
    &__Last {
        
        
        color: rgba($color: #ffffff, $alpha: 0.6);
        
        margin: 0 0 20px;

        @include noselect();
    }

    &__Follow {


        border-radius: 20px;

        padding: 10px 40px;
        
        border: 1px solid rgba($color: #fff, $alpha: 0);

        color: #fff;
        background: rgba($color: #fff, $alpha: 0.25);



        transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        

        &:hover {

            // background: #eee;

            border: 1px solid rgba($color: #fff, $alpha: 0.2);

            background: rgba($color: #fff, $alpha: 0.15);

            cursor: pointer;
        }
    }
}