.container {

	&.hide {

		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	&.show {
		opacity: 1;
		visibility: visible;

		pointer-events: auto;

		transition: opacity 0.6s ease-in-out;
	}
}