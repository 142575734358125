.Chat {

    display: flex;
    flex-flow: column;


    flex-grow: 4;

    padding: 20px;

    color: #fff;

    // background: green;

    h2 {

        margin-bottom: 10px;
    }

    &__Messages {

        flex-grow: 10;

        // background: red;

    }

    &__Input {

        border-radius: 20px;

        font-size: 14px;
        line-height: 1.2em;

        padding: 10px 16px;

        // background: #eee;
        background: rgba($color: #fff, $alpha: 0.05);
        // background: none;

        // border: none;
        border: 1px solid rgba($color: #fff, $alpha: 0);

        transition:
            border 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

        &:focus {

            border-color: $skyBlue;
            outline: none;
        }

        // background: yellow;

    }

    &__Reactions {

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;

        font-size: 16px;

        margin: 0 0 10px;

        &__Button {

            cursor: pointer;

            border-radius: 50%;

            padding: 10px;

            background: rgba($color: #fff, $alpha: 0.05);

            transition:
                background 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {

                background: rgba($color: #fff, $alpha: 0.15);
            }
        }
    }
}