.Sidebar {

    position: absolute;
    z-index: 9999999;

    display: flex;
    flex-flow: column nowrap;
    
    top: 0;
    right: 0;
    
    width: 290px;
    height: 100vh;
    // margin: 10px;
    

    // background: rgba($color: #181819, $alpha: 0.85);
    background: rgba($color: #181819, $alpha: 1);
    backdrop-filter: blur(16px);
    
    backdrop-filter: blur(16px) contrast(60%);
    -webkit-backdrop-filter: blur(16px) contrast(60%);
    
    // background: orange;



    // display: none;
    
    @include for_mobile_wide {
    
        position: relative;
    
        // margin-top: calc(100vw * 9/16);
    
        width: 100vw;
    
        height: auto;

        // box-shadow: none;
    

    }
    
}

