.Video {

    
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    
    position: relative;

    width: calc(100vw - 290px);
    // width: 100vw;
    height: 100vh;

    // background: salmon;
    background: #f5f5f5;

    
    &__Player {


        position: relative;

        width: calc(100%);
        // width: 100vw;
        height: 100vh;

        overflow: hidden;

        // margin: 20px;
        
        // background: black;
        background: #333;
        

        &__Stream {

            // margin: 20px;
            position: relative;
        
            // width: calc(100% - 40px);
            // width: 100%;
            // height: auto;

            min-width: 100%;
            min-height: 100%;
            height: 100%;
    
            // background: #eee;
        }


        &__Control {
        
            display: flex;
            flex-flow: wrap nowrap;
            justify-content: space-between;
            align-items: center;
        
            width: 100%;

            // margin: 0 20px;
        
            position: absolute;
            z-index: 99999;
        
            left: 0;
            bottom: 0;

            padding: 0 0 16px;
        
            // background: aqua;
        
            font-size: 30px;
            color: #fff;

            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 80%);

            @include noselect();
        
            // display: none;
        
            &__Camera {

                display: flex;
                flex-flow: row nowrap;
                
                width: 90px;
                line-height: 30px;
        
                margin: 0 16px;

                cursor: default;
        
                // background: red;


                img {

                    width: 30px;
                    height: 30px;

                    // background: blue;
                }
        
                span {

                    
                    font-family: "Georgia", "Apple Symbols", serif;
                    
                    cursor: pointer;
                    
                    line-height: 30px;
                    
                    transition:
                    opacity 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

                
                    // background: teal;
        
                    &:hover {
        
                        opacity: 0.4;
                    }
                }
        
            }
            
            

            &__Mute {

                width: 90px;
                height: 30px;

                text-align: right;

                margin: 0 16px;
        
                cursor: pointer;
                pointer-events: none;

                // background: red;

                img {

                    width: 30px;
                    height: 30px;
                    
                    pointer-events: auto;
                    // background: blue;
                }
            }
        }
    }
}







.Spacer {

    // background: red;

    opacity: 0;

    display: none;
}


.Room {


    display: flex;
    flex-flow: row nowrap;

    align-items: center;

    color: #fff;

    font-size: 14px;
    line-height: 1.1em;

    // background: red;

    
    &__Live {

        color: #000;

        text-transform: uppercase;

        font-weight: 600;

        padding: 2px 4px;
        margin: 0 16px;

        border-radius: 2px;

        background: $skyBlue;

    }
}