@include for_mobile_wide {

    .Bumper {
        
        position: fixed;

        // display: none;
        top: 0;
        left: 0;

        width: 100vw;

        height: 40px;

        transform: translate3d(0, -100%, 0);

        // transition:
        //     opacity 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

        // opacity: 0;

        &.show {
            
            // opacity: 1;

            transform: translate3d(0, 0, 0);
            
        }

        &__Label {


            font-size: 16px;
        }

        &__Name {

            font-size: 24px;
        }
    }

}